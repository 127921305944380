import React from 'react';
import styled, { css } from 'styled-components';
import Check from '../../../images/product-assets/Check.svg';
import Trust from '../../../images/product-assets/trust.svg';
import Configuration from '../../../images/product-assets/configuration.svg';
import Coverage from '../../../images/product-assets/360-coverage.svg';
import No_code from '../../../images/product-assets/no-code.svg';
import Hybrid from '../../../images/product-assets/hybrid.svg';
import Api_direct from '../../../images/product-assets/api-direct.svg';
import Authenticate from '../../../images/logos/authenticate_light.svg';
import Ellipse1 from '../../../images/product-assets/Ellipse1.svg';
import Ellipse3 from '../../../images/product-assets/Ellipse3.svg';

const Container = styled.section`
    position: relative;
    overflow: hidden;
`;

const InfoContainer = styled.div`
    z-index: 1;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 20px;

    @media screen and (max-width: 500px) {
        padding: 0 16px;
    }
`;

const Heading = styled.h1`
    color: #03045e;
    font-size: 42px;
    font-weight: 500;
    line-height: 48px;
    text-align: center;

    ${({ first }) =>
        first &&
        css`
            font-size: 36px;
            font-weight: 500;
            line-height: 43px;
            color: #ffffffd9;
            text-align: left;
            margin-top: 4px;
        `}

    @media screen and (max-width: 500px) {
        font-size: 24px;
        line-height: 28px;
    }
`;

const CardContainer = styled.div`
    margin-top: 85px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;

    @media screen and (max-width: 500px) {
        margin-top: 64px;
        grid-template-columns: repeat(1, 1fr);
        gap: 24px;
    }
`;

const InfoCards = styled.div`
    border-radius: 20px;
    background: #f5faff;
    padding: 24px;
    position: relative;
    min-height: 250px;

    ${({ first }) =>
        first &&
        css`
            background: #03045e;
            padding: 34px;

            @media screen and (max-width: 500px) {
                min-height: fit-content;
                padding: 16px;
            }
        `}

    &:first-child, &:last-child {
        @media screen and (min-width: 500px) {
            grid-column: span 2;
        }
    }

    @media screen and (max-width: 500px) {
        border-radius: 12px;
        min-height: ${({ first }) => (first ? 'auto' : '180px')};
    }
`;

const CardHeading = styled.p`
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: #333;

    ${({ detail }) =>
        detail &&
        css`
            font-weight: 400;
            color: #03045e;
            margin-top: 20px;
        `}

    @media screen and (max-width: 500px) {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;

        ${({ detail }) =>
            detail &&
            css`
                font-weight: 500;
            `}
    }
`;

const CardDesc = styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 8px;
    color: #52525b;

    @media screen and (max-width: 500px) {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;

        ${({ detail }) =>
            detail &&
            css`
                padding: 0 20px;
            `}
    }
`;

const Ul = styled.ul`
    margin-left: 24px;
`;

const BottomDeck = styled.div`
    position: absolute;
    bottom: 24px;
    left: 24px;
    right: 24px;

    @media screen and (max-width: 500px) {
        bottom: 16px;
        left: 16px;
        right: 16px;
        position: ${({ overflow }) => (overflow ? 'static' : 'absolute')};
    }
`;

const CheckIcon = styled.img`
    @media screen and (max-width: 500px) {
        height: 24px;
    }
`;

const BrandLogo = styled.img`
    display: block;
    margin-top: 48px;
    height: 38px;
    width: auto;

    @media screen and (max-width: 500px) {
        height: 17px;
        margin-top: 24px;
    }
`;

const ProductStructure = styled.div`
    margin-top: 74px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;

    @media screen and (max-width: 500px) {
        margin-top: 36px;
        grid-template-columns: repeat(1, 1fr);
    }
`;

const DetailCard = styled.div`
    text-align: center;
`;
const DetailLogo = styled.img`
    ${({ detail }) =>
        detail &&
        css`
            @media screen and (max-width: 500px) {
                width: 24px;
                height: 24px;
                padding: 0;
            }
        `}
`;

const TypeContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin-top: 74px;
    @media screen and (max-width: 500px) {
        margin-top: 36px;
        grid-template-columns: repeat(1, 1fr);
    }
`;

const ProductTypeCard = styled.div`
    background: #fff;
    border-radius: 16px;
    border: 1px solid #e8e8e8;
    padding: ${({ mobile }) => (mobile ? '12px 8px' : '24px')};

    @media screen and (max-width: 500px) {
        display: ${({ mobile }) => (mobile ? 'block' : 'none')};
    }

    &:hover {
        box-shadow: 0px 2px 20px 0px #03045e1a;
    }
`;

const CardHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    color: #333;
    margin-bottom: 36px;

    @media screen and (max-width: 500px) {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        gap: 2px;
        margin-bottom: 0;
    }
`;

const MobileSection = styled.div`
    margin-top: 74px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    @media screen and (min-width: 500px) {
        display: none;
    }
`;

const EllipseOne = styled.img`
    position: absolute;
    right: -4%;
    top: 35%;
    z-index: -1;

    @media screen and (max-width: 500px) {
        display: none;
    }
`;

const EllipseThree = styled.img`
    position: absolute;
    left: -5%;
    bottom: 0%;
    z-index: -1;

    @media screen and (max-width: 500px) {
        display: none;
    }
`;

function Information() {
    return (
        <Container id="Information">
            <InfoContainer>
                <Heading>Make Every Step User-Specific</Heading>
                <ProductStructure>
                    <DetailCard>
                        <DetailLogo src={Trust} alt="Trust" />
                        <CardHeading detail>Take Control (Risk Deterrence)</CardHeading>
                        <CardDesc detail>
                            Real-time user insights using match scores & confidence signals reduces
                            friction & improves customer onboarding experience.
                        </CardDesc>
                    </DetailCard>
                    <DetailCard>
                        <DetailLogo src={Configuration} alt="Configuration" />
                        <CardHeading detail>Customize your Experience</CardHeading>
                        <CardDesc detail>
                            Verify identities, generate verification links, and create workflows
                            with one click. No code, hybrid or API direct.
                        </CardDesc>
                        <MobileSection>
                            <ProductTypeCard mobile>
                                <CardHeader>
                                    <DetailLogo src={No_code} alt="Trust" /> No Code
                                </CardHeader>
                            </ProductTypeCard>
                            <ProductTypeCard mobile>
                                <CardHeader>
                                    {' '}
                                    <DetailLogo src={Hybrid} alt="Hybrid" />
                                    Hybrid
                                </CardHeader>
                            </ProductTypeCard>
                            <ProductTypeCard mobile>
                                <CardHeader>
                                    {' '}
                                    <DetailLogo src={Api_direct} alt="Api_direct" />
                                    API Direct
                                </CardHeader>
                            </ProductTypeCard>
                        </MobileSection>
                    </DetailCard>
                    <DetailCard>
                        <DetailLogo src={Coverage} alt="360-Coverage" />
                        <CardHeading detail>360° Coverage</CardHeading>
                        <CardDesc detail>
                            Authenticate forensically analyzes government-issued photo
                            identification for fraud with optional facial recognition and liveness
                            detection.
                        </CardDesc>
                    </DetailCard>
                </ProductStructure>
                <TypeContainer>
                    <ProductTypeCard>
                        <CardHeader>
                            <DetailLogo src={No_code} alt="Trust" type /> No Code
                        </CardHeader>
                        <CardDesc>
                            Start verifying identities with one click. Generate Medallion™
                            verification links from the Authenticate dashboard with no integration
                            required. Create or change workflows with a few clicks.
                        </CardDesc>
                    </ProductTypeCard>
                    <ProductTypeCard>
                        <CardHeader>
                            <DetailLogo src={Hybrid} alt="Hybrid" type />
                            Hybrid
                        </CardHeader>
                        <CardDesc>
                            Verify users via API and dynamically handle fallbacks and exceptions
                            with Authenticate's UI. Configure Authenticate to take different actions
                            depending on the outcome of each verification processed.
                        </CardDesc>
                    </ProductTypeCard>
                    <ProductTypeCard>
                        <CardHeader>
                            <DetailLogo src={Api_direct} alt="Api_direct" type />
                            API Direct
                        </CardHeader>
                        <CardDesc>
                            Access a wide range of high-assurance identity verification processes
                            through real-time APIs. Quickly integrate Authenticate’s core
                            capabilities to create your own flows & processes that meet specific
                            needs.
                        </CardDesc>
                    </ProductTypeCard>
                </TypeContainer>
                <CardContainer>
                    <InfoCards first>
                        <CheckIcon src={Check} alt="Check mark" />
                        <BrandLogo src={Authenticate} alt="Authenticate logo" />
                        <Heading first>for every use case</Heading>
                    </InfoCards>
                    <InfoCards>
                        <BottomDeck>
                            <CardHeading>Balance Convenience and Security</CardHeading>
                            <CardDesc>
                                Authenticate simplified the identity verification process with
                                Medallion™ so users can easily prove their identity through a secure
                                web native application.
                            </CardDesc>
                        </BottomDeck>
                    </InfoCards>
                    <InfoCards>
                        <BottomDeck>
                            <CardHeading>Prevent Any Fraudulent Activities</CardHeading>
                            <CardDesc>
                                Use Authenticate for:
                                <Ul>
                                    <li>Driver license verification</li>
                                    <li>Age verification</li>
                                    <li>KYC and AML requirements</li>
                                </Ul>
                            </CardDesc>
                        </BottomDeck>
                    </InfoCards>
                    <InfoCards>
                        <BottomDeck overflow>
                            <CardHeading>Meet Regulatory Requirements</CardHeading>
                            <CardDesc>
                                Delight your Security, Compliance, and Risk teams. Authenticate
                                helps businesses to comply with industry standards, rules and
                                regulations by maintaining the highest level of security with annual
                                SOC 2 Type 2 and Health Insurance Portability and Accountability Act
                                (HIPPA) certifications, Payment Card Industry Data Security Standard
                                (PCI DSS) compliance, and compliance with General Data Protection
                                Regulation (GDPR), California Consumer Privacy Act (CCPA), Drivers
                                Privacy Protection Act (DPPA), Gramm-Leach-Bliley Act (GLBA) and
                                more.
                            </CardDesc>
                        </BottomDeck>
                    </InfoCards>
                </CardContainer>
            </InfoContainer>
            <EllipseThree src={Ellipse3} alt="Ellipse 3" />
            <EllipseOne src={Ellipse1} alt="Ellipse 1" />
        </Container>
    );
}

export default Information;
